import { 
    UPLOAD_FILES,
    UPLOAD_PROGRESS,
    UPLOAD_SUCCESS,
    UPLOAD_FAILURE,
    GET_FILES_SUCCESS,
    GET_FILES,
    DELETE_FILES,
    DELETE_FILES_SUCCESS,
    GET_PERIZIA_FILES_SUCCESS,
    DELETE_FILES_ERROR,
    POST_CERTIFICA_PERIZIA_FILES,
    POST_CERTIFICA_PERIZIA_FILES_SUCCESS,
    POST_CERTIFICA_PERIZIA_FILES_ERROR,
    GET_PERIZIA_FILES_TO_CERTIFICATE_SUCCESS,
} from "./actionTypes";

// Stato iniziale
const initialState = {
    uploading: false, // Flag per indicare se è in corso l'upload
    progress: 0, // Progresso di upload in percentuale
    error: null, // Eventuale errore di upload
    fileUploaded:[],
    files:[],
    deleteFileSuccess:false,
    perizia:null,
    deleteErrorMessage:null,
    certificaFilesSuccessMessage:null,
    certificaFilesErrorMessage:null,
    perizieIds:[]
};
  
  // Reducer
const fileUploadReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FILES:
            return {
                ...state,
                files:[]
            };
        case GET_FILES_SUCCESS:
            return {
                ...state,
                files:action.payload.files
            };
        case GET_PERIZIA_FILES_TO_CERTIFICATE_SUCCESS:
            return {
                ...state,
                perizieIds:action.payload.perizieIds
            };
        case GET_PERIZIA_FILES_SUCCESS:
            return {
                ...state,
                perizia:action.payload.perizia
            };
        case POST_CERTIFICA_PERIZIA_FILES:
            return {
                ...state,
                certificaFilesSuccessMessage:null,
                certificaFilesErrorMessage:null,
            };
        case POST_CERTIFICA_PERIZIA_FILES_SUCCESS:
            return {
                ...state,
                certificaFilesSuccessMessage:action.payload.message
            };
        case POST_CERTIFICA_PERIZIA_FILES_ERROR:
            return {
                ...state,
                certificaFilesErrorMessage:action.payload.message
            };
        case DELETE_FILES:
            return {
                ...state,
                deleteErrorMessage: null,
                deleteFileSuccess:false
            };
        case DELETE_FILES_SUCCESS:
            return {
                ...state,
                // files: state?.files?.filter((file) => file?.id !== action.payload.file_id),
                deleteFileSuccess:action.payload.status
            };
        case DELETE_FILES_ERROR:
            return {
                ...state,
                deleteErrorMessage: action.payload.deleteErrorMessage
            };
        case UPLOAD_FILES:
            return {
                ...state,
                uploading: true,
                progress: 0,
                error: null,
            };
        case UPLOAD_PROGRESS:
            return {
                ...state,
                progress: action.payload.progress,
                fileUploaded: [...state.fileUploaded, action.payload.fileName],
            };
        case UPLOAD_SUCCESS:
            return {
                ...state,
                uploading: false,
                progress: 100,
                error: null,
            };
        case UPLOAD_FAILURE:
            return {
                ...state,
                uploading: false,
                progress: 0,
                error: action.payload.error,
            };
        default:
            return state;
    }
};
  
  export default fileUploadReducer;