import { call, put, takeEvery, takeLatest, take } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN, VALIDATE_TOKEN, ME_INFO, FORGOT_PASSWORD, UPDATE_EMAIL, UPDATE_PASSWORD, UPDATE_USER_DATA } from "./actionTypes";
import { 
  apiError, 
  forgotPasswordSuccess, 
  updateEmailSuccess,
  loginSuccess, 
  meInfo, 
  meInfoSuccess, 
  updatePasswordSuccess,
  updateUserDataSuccess} from "./actions";

//Include Both Helper File with needed methods
import { 
  getMe,
  postLogin,
  postPasswordForgot,
  postValidateToken,
  putCambiaEmail,
  putCambiaPassword,
  putCambiaUserData
} from "helpers/ara_helper";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
      email: user.email,
      password: user.password,
    });
    if(response){

      if(response?.status === "error"){
        yield put(apiError(response));
        return;
      }
      
      if(response?.status === "errors"){
        if(typeof response?.message === "object"){
          const keys = Object.keys(response?.message);
          yield put(apiError({message: response?.message[keys[0]][0]}));
          return;
        }
        return;
      }

      localStorage.setItem("hailmanagement_token", response?.token);
      yield put(loginSuccess(response));
      history.push("/dashboard");
    }
  } catch (error) {
    yield put(apiError(error?.response?.data));
  }
}

function* validateToken({ }) {
  try {
    const response = yield call(postValidateToken);
    if(response){
      if(response?.status === "error"){
        localStorage.removeItem("hailmanagement_token");
        yield put(apiError(response));
        return;
      }
      localStorage.setItem("hailmanagement_token", response?.data)
      yield put(meInfo());
    }
  } catch (error) {
    localStorage.removeItem("hailmanagement_token");
    yield put(apiError(error?.response?.data));
  }
}

function* meInfoSaga({ }) {
  try {
    const response = yield call(getMe);
    if(response){
      if(response?.status === "error"){
        yield put(apiError(response));
        return;
      }

      yield put(meInfoSuccess(response?.data));
      
    }
  } catch (error) {
    yield put(apiError(error?.response?.data));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("hailmanagement_token");
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* forgotPassword({ payload }) {
  try {
    const response = yield call(postPasswordForgot, payload);
    if(response){

      if(response?.status === "error" || response?.status === "errors"){
        if(typeof response?.message === "object"){
          const keys = Object.keys(response?.message);
          yield put(apiError({message: response?.message[keys[0]][0]}));
          return;
        }

        yield put(apiError(response?.message));
        return;
      }

      yield put(forgotPasswordSuccess(response?.message));
    }
  } catch (error) {
    yield put(apiError(error?.response?.data));
  }
}

function* updateEmail({ payload }) {
  try {
    const response = yield call(putCambiaEmail, payload);
    if(response){

      if(response?.status === "error" || response?.status === "errors"){
        if(typeof response?.message === "object"){
          const keys = Object.keys(response?.message);
          yield put(apiError({message: response?.message[keys[0]][0]}));
          return;
        }

        yield put(apiError(response));
        return;
      }

      yield put(meInfo());
      yield put(updateEmailSuccess(response?.message));
    }
  } catch (error) {
    yield put(apiError(error?.response?.data));
  }
}

function* updatePassword({ payload }) {
  try {
    const response = yield call(putCambiaPassword, payload);
    if(response){

      if(response?.status === "error" || response?.status === "errors"){
        if(typeof response?.message === "object"){
          const keys = Object.keys(response?.message);
          yield put(apiError({message: response?.message[keys[0]][0]}));
          return;
        }

        yield put(apiError(response));
        return;
      }

      yield put(updatePasswordSuccess(response?.message));
    }
  } catch (error) {
    yield put(apiError(error?.response?.data));
  }
}

function* updateUserDatas({ payload }) {
  try {
    const response = yield call(putCambiaUserData, payload);
    if(response){

      if(response?.status === "error" || response?.status === "errors"){
        if(typeof response?.message === "object"){
          const keys = Object.keys(response?.message);
          yield put(apiError({message: response?.message[keys[0]][0]}));
          return;
        }

        yield put(apiError(response));
        return;
      }

      yield put(meInfo());
      yield put(updateUserDataSuccess(response?.message));
    }
  } catch (error) {
    yield put(apiError(error?.response?.data));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeLatest(VALIDATE_TOKEN, validateToken);
  yield takeLatest(ME_INFO, meInfoSaga);
  yield takeLatest(FORGOT_PASSWORD, forgotPassword);
  yield takeLatest(UPDATE_EMAIL, updateEmail);
  yield takeLatest(UPDATE_PASSWORD, updatePassword);
  yield takeLatest(UPDATE_USER_DATA, updateUserDatas);
}

export default authSaga;
