import { call, put, takeEvery, delay, takeLatest } from "redux-saga/effects";
import { 
    deleteFiles,
    getListPerizieIdsOnlyToCertificate,
    getPeriziaCertificateFiles,
    getPeriziaFiles,
    postFiles, 
    postPeriziaCertificaFiles
  } from "helpers/ara_helper";
import { 
    UPLOAD_FILES,
    UPLOAD_PROGRESS,
    UPLOAD_SUCCESS,
    UPLOAD_FAILURE,
    GET_FILES,
    DELETE_FILES,
    GET_PERIZIA_FILES,
    POST_CERTIFICA_PERIZIA_FILES,
    GET_PERIZIA_FILES_TO_CERTIFICATE,
} from "./actionTypes";
import axios from "axios";

import { 
    uploadFiles,
    updateProgress,
    uploadSuccess,
    uploadFailure,
    getFilesSuccess,
    deleteFilesSuccess,
    getPeriziaCertificateFilesSuccess,
    deleteFilesError,
    postCertificaPeriziaFilesSuccess,
    postCertificaPeriziaFilesError,
    getPeriziaFilesToCertificateSuccess,
  } from "./actions";
//getPeriziaFiles
function* handleUploadFiles(action) {

    const { files, perizia_id } = action.payload;
    yield put(updateProgress(1, []));
    try {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const chunkSize = 1024 * 1024;
            const totalChunks = Math.ceil(file.size / chunkSize);
            let currentChunk = 0;

            while (currentChunk < totalChunks) {
                const start = currentChunk * chunkSize;
                const end = Math.min(start + chunkSize, file.size);
                const chunk = file.slice(start, end);

                const formData = new FormData();
                formData.append('file', chunk);
                formData.append('perizia_id', perizia_id);
                formData.append('filename', file.name);
                formData.append('fileExt', file.name.split('.').pop());
                formData.append('chunk', currentChunk);
                formData.append('chunks', totalChunks);

                yield call(postFiles, formData);

                currentChunk++;
            }

            yield put(updateProgress((i + 1) / files.length * 100, file.name));
        }
        
    } catch (error) {
        yield put(uploadFailure(error.message));
    }
}

function* handleGetFiles(action){
    const { perizia_id } = action.payload;
    try {
        const res = yield call(getPeriziaFiles, perizia_id);
        if(res?.files){
            yield put(getFilesSuccess(res.files));
        }
    } catch (error) {
        console.error(error?.message);
    }
}

function* handleGetPeriziaFiles(action){
    const { perizia_id } = action.payload;
    try {
        const res = yield call(getPeriziaCertificateFiles, perizia_id);
        if(res){
            yield put(getPeriziaCertificateFilesSuccess(res));
        }
    } catch (error) {
        console.error(error?.message);
    }
}

function* handleDeleteFiles(action){
    const { file_id } = action.payload;
    try {
        const res = yield call(deleteFiles, file_id);
        if(res?.status && res.status === "success"){
            yield put(deleteFilesSuccess(true));
        }else{
            yield put(deleteFilesError(res.message));
        }
    } catch (error) {
        console.error(error.response?.data?.message);
        yield put(deleteFilesError(error.response?.data?.message));
    }
}

function* handlePostCertificaPeriziaFiles(action){
    const { perizia_id } = action.payload;

    let data = {}

    if(Array.isArray(perizia_id)){
        data = {perizie_ids:perizia_id}
    }else{
        data = {perizia_id};
    }

    try {
        const res = yield call(postPeriziaCertificaFiles, data);
        yield put(postCertificaPeriziaFilesSuccess(res?.message));
    } catch (error) {
        console.error(error.response?.data?.message);
        yield put(postCertificaPeriziaFilesError(error.response?.data?.message));
    }
}

function* handlePeriziaToCertificate(action){
    const { perizieIds } = action.payload;
    try {
        const res = yield call(getListPerizieIdsOnlyToCertificate, {ids:perizieIds});
        yield put(getPeriziaFilesToCertificateSuccess(res));
    } catch (error) {
        console.error(error.response?.data?.message);
    }
}

function* FilesSaga() {
    yield takeEvery(UPLOAD_FILES, handleUploadFiles);
    yield takeLatest(GET_FILES, handleGetFiles);
    yield takeLatest(GET_PERIZIA_FILES, handleGetPeriziaFiles);
    yield takeLatest(DELETE_FILES, handleDeleteFiles);
    yield takeLatest(POST_CERTIFICA_PERIZIA_FILES, handlePostCertificaPeriziaFiles);
    yield takeLatest(GET_PERIZIA_FILES_TO_CERTIFICATE, handlePeriziaToCertificate);
}

export default FilesSaga;