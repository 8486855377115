import React, { useEffect, useState } from "react"

import { Alert, Button, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword } from "store/actions";

const FormPassword = ({
    isActiveTab
}) => {

    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useDispatch();
    const validation = useFormik({    
        initialValues: {
          old_password: '',
          password: '',
          password_confirmation: ''
        },

        validationSchema: Yup.object({
          old_password: Yup.string().required("Please Enter Your Old Password"),
          password: Yup.string().min(8).required("Please Enter Your Password"),
          password_confirmation: Yup.string().min(8).required("Please Enter Your Password")
            .oneOf([Yup.ref('password'), null], 'Password must match')
        }),
        onSubmit: (values) => {
            console.log(values);
            dispatch(updatePassword(values));
        }
    });

    const { updatePasswordMessage, updatePasswordError} = useSelector(state => ({
        updatePasswordError: state.Login.error,
        updatePasswordMessage: state.Login.updatePasswordMessage,
    }));

    useEffect(() => {
        if(updatePasswordMessage){
            setIsEdit(false);
        }
    },[updatePasswordMessage]);

    useEffect(() => {
        if(!isActiveTab){
            setIsEdit(false);
        }
    },[isActiveTab]);

    return(
        <Container>
            <Row className="mb-3">
                <Col>
                    <button
                    type="button"
                    className={`btn ${isEdit ? 'btn-warning' : 'btn-primary'}`}
                    onClick={() => setIsEdit(!isEdit)}
                    >
                        <i className="bx bx-edit-alt font-size-16 align-middle me-2"></i>
                        {isEdit ? 'Annulla modifica' : 'Modifica'}
                    </button>
                </Col>
            </Row>
            <Row>
                <Col>
                    {(updatePasswordError && isEdit) ? <Alert color="danger">{updatePasswordError}</Alert> : null}
                    {(updatePasswordMessage && !isEdit) ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {updatePasswordMessage}
                      </Alert>
                    ) : null}
                    {!isEdit ? <h3>Clicca sul pulsante modifica per cambiare la password.</h3> : null} 
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        {isEdit ? (
                            <>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">Vecchia Password</Label>
                                    <Input
                                        name="old_password"
                                        type="password"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Inserisci vecchia password"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.old_password && validation.errors.old_password ? true : false
                                        }
                                    />
                                    {validation.touched.old_password && validation.errors.old_password ? (
                                        <FormFeedback type="invalid">{validation.errors.old_password}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">Nuova Password</Label>
                                    <Input
                                        name="password"
                                        type="password"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Inserisci nuova password"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.password && validation.errors.password ? true : false
                                        }
                                    />
                                    {validation.touched.password && validation.errors.password ? (
                                        <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">Ripeti nuova Password</Label>
                                    <Input
                                        name="password_confirmation"
                                        type="password"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Ripeti nuova password"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.password_confirmation && validation.errors.password_confirmation ? true : false
                                        }
                                    />
                                    {validation.touched.password_confirmation && validation.errors.password_confirmation ? (
                                        <FormFeedback type="invalid">{validation.errors.password_confirmation}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3 d-flex justify-content-end">
                                    <Button
                                        color="primary"
                                        disabled={!(validation.isValid && validation.dirty)}
                                    >
                                        Modifica Password
                                    </Button>
                                </div>
                            </>
                        ): null}
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default FormPassword;