import TableContainer from "components/Common/TableContainer";
import { userRoles } from "constants/layout";
import { userRole } from "helpers/utility";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Modal } from "reactstrap";
import { nuovoPerito } from "store/actions";
import { collegaPeritoIspettore, deletePerito, ottieniPeriti, scollegaPeritoIspettore } from "store/users/actions";
import AddPeritoModal from "./addPeritoModal";
import NewUserModal from "./newUserModal";

const ListUsers = () => {

    const [selectRemovedUser, setSelectedRemovedUser] = useState();
    const [selectDissociaPerito, setSelectDissociaPerito] = useState();
    const [newUserModalOpen, setNewUserModalOpen] = useState(false);
    const [addPeritoModalOpen, setAddPeritoModalOpen] = useState(false);
    const fetchObj = useRef();
    
    const columns = useMemo(
        () => [
            {
                Header: 'CODICE PERITO',
                accessor: 'codice_perito',
            },
            {
                Header: 'RUOLI',
                accessor: 'ruoli',
                disableFilters: true,
                disableSortBy: true,
                Cell: ({cell}) => {
                    return <div>{cell?.value[0]}</div>;
                },
            },
            {
                Header: 'COGNOME',
                accessor: 'surname',
                disableFilters: true,
            },
            {
                Header: 'NOME',
                accessor: 'name',
                disableFilters: true,
            },
            {
                Header: 'EMAIL',
                accessor: 'email',
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: 'AZIONI',
                accessor: '',
                disableSortBy: true,
                disableFilters: true,
                Cell: (({cell}) => {
                    return <div>
                        <button
                            onClick={() => {
                                if(userRole(user) === userRoles.ADMIN){
                                    setSelectedRemovedUser(cell?.row?.original?.user_id)
                                }
                                
                                if(userRole(user) === userRoles.ISPETTORE){
                                    setSelectDissociaPerito(cell?.row?.original?.id)
                                }
                            }}
                            type="button"
                            className="btn btn-danger"
                          >
                            <i className="mdi mdi-trash-can"></i>
                          </button>
                    </div>
                })
            },
        ],
    []);

    const dispatch = useDispatch();
    const { 
        usersDataPaginate, 
        deletePeritoStatus, 
        newPeritoStatus, 
        user, 
        collegaPeritoIspettoreMessage,
        scollegaPeritoIspettoreMessage 
    } = useSelector(state => ({
        collegaPeritoIspettoreMessage: state.Users?.collegaPeritoIspettoreMessage,
        scollegaPeritoIspettoreMessage: state.Users?.scollegaPeritoIspettoreMessage,
        usersDataPaginate: state.Users?.usersDataPaginate,
        deletePeritoStatus: state.Users?.deletePeritoStatus,
        newPeritoStatus: state.Admin?.newPeritoStatus,
        user: state.Login?.user?.user,
    }));

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy, filters }) => {
        const query  = {"paginate":true, page:pageIndex+1};

        if(sortBy && sortBy?.length){
            const filterSortBy = sortBy.filter(sortBbj => (sortBbj?.id !== null && sortBbj?.id !== undefined));
            filterSortBy.forEach(sortObj => {
                query[sortObj.id] = !sortObj.desc ? 'asc' : 'desc';
            });
        }
        
        if(filters && filters?.length){
            query.text = filters[0].value;
        }

        fetchObj.current = query;

        dispatch(ottieniPeriti(query));
    },[]);

    const deleteUser = () => {
        dispatch(deletePerito(selectRemovedUser))
    }
    
    const dissociaPerito = () => {
        console.log("lllll", selectDissociaPerito);
        dispatch(scollegaPeritoIspettore(selectDissociaPerito))
    }

    const createUserCallback = (values) => {
        dispatch(nuovoPerito(values));
    }
    
    const addPeritoCallback = (values) => {
        dispatch(collegaPeritoIspettore(values?.perito?.value));
    }

    useEffect(() => {
        if( deletePeritoStatus || 
            newPeritoStatus || 
            collegaPeritoIspettoreMessage ||
            scollegaPeritoIspettoreMessage){
            if(fetchObj.current){
                setSelectedRemovedUser();
                setSelectDissociaPerito();
                setNewUserModalOpen(false);
                setAddPeritoModalOpen(false);
                dispatch(ottieniPeriti(fetchObj.current));
            }
        }
    },[
        deletePeritoStatus, 
        newPeritoStatus, 
        collegaPeritoIspettoreMessage,
        scollegaPeritoIspettoreMessage
    ]);

    return(
        <>
            <Alert color="warning" role="alert">
                Per ordinare colonne multiple tenere premuto il tasto della tastiera alt + click del mouse nelle colonne che si vogliono ordinare
            </Alert>
            {userRole(user) === userRoles.ADMIN ? (
                <Button
                    color="success"
                    className="btn btn-success mb-2"
                    onClick={() => setNewUserModalOpen(true)}
                >
                    Aggiungi Utente
                </Button>  
            ) : null}
            
            {userRole(user) === userRoles.ISPETTORE ? (
                <Button
                    color="success"
                    className="btn btn-success mb-2"
                    onClick={() => setAddPeritoModalOpen(true)}
                >
                    Aggiungi Perito
                </Button>  
            ) : null}
            
            <TableContainer
                columns={columns}
                data={usersDataPaginate?.data || []}
                fetchData={fetchData}
                controlledPageCount={usersDataPaginate?.last_page}
                customPageSizeOptions
                customPageSize={10}
                className="custom-header-css"
            />
            <Modal
                isOpen={selectRemovedUser ? true : false}
                backdrop={'static'}
                id="staticBackdrop"
            >
                <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Elimina Utente</h5>
                <button type="button" className="btn-close"
                    onClick={() => {
                        setSelectedRemovedUser();
                    }} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <h2>Sei sicuro?</h2>
                    <p>Se si conferma, il sistema butterà fuori il perito dall'applicazione quando sarà online e non gli permetterà di rientrare.</p>
                    <p>Al tempo stesso non verranno eliminate nè le perizie, nè il nominativo del perito dal database che rimarranno nello storico.</p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-light" onClick={() => {
                        setSelectedRemovedUser();
                    }}>Annulla</button>
                    <button type="button" onClick={deleteUser} className="btn btn-danger">Procedi elimina Utente</button>
                </div>
            </Modal>
            <Modal
                isOpen={selectDissociaPerito ? true : false}
                backdrop={'static'}
                id="staticBackdropDissociaPerito"
            >
                <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Dissocia Perito</h5>
                <button type="button" className="btn-close"
                    onClick={() => {
                        setSelectDissociaPerito();
                    }} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <h2>Sei sicuro?</h2>
                    <p>Se si conferma, il sistema rimuoverà il perito da quelli da te gestiti.</p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-light" onClick={() => {
                        setSelectDissociaPerito();
                    }}>Annulla</button>
                    <button type="button" onClick={dissociaPerito} className="btn btn-danger">Procedi dissocia Perito</button>
                </div>
            </Modal>
            <NewUserModal isOpen={newUserModalOpen} closeModal={() => setNewUserModalOpen(false)} createUserCallback={createUserCallback} />
            <AddPeritoModal isOpen={addPeritoModalOpen} closeModal={() => setAddPeritoModalOpen(false)} addPeritoCallback={addPeritoCallback} />
        </>
    )
}

export default ListUsers