import { 
  deleteUser, 
  getListUsers, 
  putCollegaPeritoIspettore, 
  putScollegaPeritoIspettore 
} from "helpers/ara_helper";
import { call, put, takeLatest, delay } from "redux-saga/effects";
import { 
  apiError, 
  ottieniPeritiSuccess, 
  deletePeritoSuccess, 
  ottieniPeritiNoPaginateSuccess, 
  collegaPeritoIspettoreSuccess,
  scollegaPeritoIspettoreSuccess
} from "store/actions";
import { 
  COLLEGA_PERITO_ISPETTORE_PUT, 
  OTTIENI_PERITI, 
  PERITO_DELETE, 
  SCOLLEGA_PERITO_ISPETTORE_PUT 
} from "./actionTypes";

function* ottieniPeriti({ payload }) {
  try {
    const response = yield call(getListUsers, payload);
    if(response){
      if(response?.status === "error"){
        yield put(apiError(response));
        return;
      }

      if(payload?.paginate){
        yield put(ottieniPeritiSuccess(response?.data))
      }else{
        yield put(ottieniPeritiNoPaginateSuccess(response?.data))
      }

    }
  } catch (error) {
    yield put(apiError(error?.response?.data));
  }
}

function* deletePerito({ payload }) {
  try {
    const response = yield call(deleteUser, payload);
    if(response){
      if(response?.status === "error"){
        yield put(apiError(response));
        return;
      }

      yield put(deletePeritoSuccess());
    }
  } catch (error) {
    yield put(apiError(error?.response?.data));
  }
}

function* collegaPeritoIspettore({ payload }) {
  try {
    const response = yield call(putCollegaPeritoIspettore, payload);
    if(response){
      if(response?.status === "error"){
        yield put(apiError(response));
        return;
      }

      yield put(collegaPeritoIspettoreSuccess(response?.message));
    }
  } catch (error) {
    yield put(apiError(error?.response?.data));
  }
}

function* scollegaPeritoIspettore({ payload }) {
  try {
    const response = yield call(putScollegaPeritoIspettore, payload);
    if(response){
      if(response?.status === "error"){
        yield put(apiError(response));
        return;
      }

      yield put(scollegaPeritoIspettoreSuccess(response?.message));
    }
  } catch (error) {
    yield put(apiError(error?.response?.data));
  }
}

function* usersSaga() {
  yield takeLatest(OTTIENI_PERITI, ottieniPeriti);
  yield takeLatest(PERITO_DELETE, deletePerito);
  yield takeLatest(COLLEGA_PERITO_ISPETTORE_PUT, collegaPeritoIspettore);
  yield takeLatest(SCOLLEGA_PERITO_ISPETTORE_PUT, scollegaPeritoIspettore);
}
  
  export default usersSaga;