import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";

import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import { meInfo, validateToken } from 'store/actions';



const App = props => {

  const dispatch = useDispatch();
  const [firstInit, setFirstInit] = useState(false);

  const { user } = useSelector(state => ({
    user: state.Login?.user,
  }));

  function getLayout() {
    return HorizontalLayout;
  }

  const checkToken = () => {
    const token = localStorage.getItem('hailmanagement_token');
    if(!token){
      setFirstInit(true);
      return;
    }

    dispatch(validateToken());
  }
 
  useEffect(() => {
    checkToken();
  },[])

  useEffect(() => {
    if(user && !firstInit){
      setFirstInit(true);
    }
  },[user])

  if(!firstInit){
    return(
      <div></div>
    )
  }

  const Layout = getLayout();
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
