import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  VALIDATE_TOKEN,
  ME_INFO,
  ME_INFO_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  UPDATE_EMAIL,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_USER_DATA,
  UPDATE_USER_DATA_SUCCESS,
} from "./actionTypes"

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  }
}

export const validateToken = () => {
  return {
    type: VALIDATE_TOKEN,
    payload: {},
  }
}

export const meInfo = () => {
  return {
    type: ME_INFO,
    payload: {},
  }
}

export const meInfoSuccess = (user) => {
  return {
    type: ME_INFO_SUCCESS,
    payload: {user},
  }
}

export const forgotPassword = data => {
  return {
    type: FORGOT_PASSWORD,
    payload: data,
  }
}

export const forgotPasswordSuccess = message => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const updateEmail = data => {
  return {
    type: UPDATE_EMAIL,
    payload: data,
  }
}

export const updateEmailSuccess = message => {
  return {
    type: UPDATE_EMAIL_SUCCESS,
    payload: message,
  }
}

export const updatePassword = data => {
  return {
    type: UPDATE_PASSWORD,
    payload: data,
  }
}

export const updatePasswordSuccess = message => {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const updateUserData = data => {
  return {
    type: UPDATE_USER_DATA,
    payload: data,
  }
}

export const updateUserDataSuccess = message => {
  return {
    type: UPDATE_USER_DATA_SUCCESS,
    payload: message,
  }
}
