import React from "react"
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Container, Row,
} from "reactstrap"

const Dashboard = () => {
  //meta title
  document.title=`Forget Password | ${process.env.REACT_APP_DOCUMENT_NAME}`;
  return (
    <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col>
            <Card outline color="primary" className="border">
              <CardHeader className="bg-transparent">
                <h5 className="my-0 text-primary">
                  <i className="mdi mdi-home-circle-outline me-3" />Benvenuto
                </h5>
              </CardHeader>
              <CardBody>
                <CardText>
                  Dashboard Ara1857 Assicurazioni
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
  );
}

export default Dashboard;
