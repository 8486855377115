import React from "react"
import { Redirect } from "react-router-dom"


// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import User from "pages/Users/index"

import Perizie from "pages/Perizie/index"
import Profilo from "pages/Profile/index"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/lista-utenti", exact: true, component: User },
  { path: "/lista-perizie", exact: true, component: Perizie },
  { path: "/profilo", exact: true, component: Profilo },

  // //profile
  // { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
]

export { publicRoutes, authProtectedRoutes }
