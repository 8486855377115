import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  ME_INFO,
  ME_INFO_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD,
  UPDATE_EMAIL,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_USER_DATA,
  UPDATE_USER_DATA_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  user: null
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        user: action.payload,
        loading: false,
      }
      break
    case ME_INFO_SUCCESS:
      state = {
        ...state,
        user: action.payload,
        loading: false,
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      const error = action.payload;
      state = { ...state, error: error?.message ?? 'Errore', loading: false }
      break
    case FORGOT_PASSWORD_SUCCESS:
      state = {
        ...state,
        forgotPasswordMessage: action.payload,
      }
      break
    case FORGOT_PASSWORD:
      state = {
        ...state,
        forgotPasswordMessage: null,
        error: null,
      }
      break
    case UPDATE_EMAIL:
      state = {
        ...state,
        updateEmailMessage: null,
        error: null,
      }
      break
    case UPDATE_EMAIL_SUCCESS:
      state = {
        ...state,
        updateEmailMessage: action.payload,
      }
      break
    case UPDATE_PASSWORD:
      state = {
        ...state,
        updatePasswordMessage: null,
        error: null,
      }
      break
    case UPDATE_PASSWORD_SUCCESS:
      state = {
        ...state,
        updatePasswordMessage: action.payload,
      }
      break
    case UPDATE_USER_DATA:
      state = {
        ...state,
        updateDataMessage: null,
        error: null,
      }
      break
    case UPDATE_USER_DATA_SUCCESS:
      state = {
        ...state,
        updateDataMessage: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
