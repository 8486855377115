import TableContainer from "components/Common/TableContainer";
import React, { useMemo } from "react"
import {
  Container,
} from "reactstrap"
import ListUsers from "./ListUsers";

const User = () => {
  //meta title
  document.title=`Lista Utenti | ${process.env.REACT_APP_DOCUMENT_NAME}`;

    


  return (
    <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <ListUsers /> 
      </Container>
    </div>
  </React.Fragment>
  );
}

export default User;
