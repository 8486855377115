import React, { useEffect, useState } from "react"

import { Alert, Button, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { updateEmail } from "store/actions";

const FormEmail = ({
    user,
    isActiveTab
}) => {

    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useDispatch();
    const validation = useFormik({    
        initialValues: {
          email: '',
          emailConfirmation: ''
        },

        validationSchema: Yup.object({
          email: Yup.string().email().required("Please Enter Your Email"),
          emailConfirmation: Yup.string().email().required("Please Enter Your Email")
            .oneOf([Yup.ref('email'), null], 'Email must match'),
        }),
        onSubmit: (values) => {
            console.log(values);
            dispatch(updateEmail(values));
        }
    });

    const { updateEmailMessage, updateEmailError} = useSelector(state => ({
        updateEmailError: state.Login.error,
        updateEmailMessage: state.Login.updateEmailMessage,
    }));

    useEffect(() => {
        if(updateEmailMessage){
            setIsEdit(false);
        }
    },[updateEmailMessage]);

    useEffect(() => {
        if(!isActiveTab){
            setIsEdit(false);
        }
    },[isActiveTab]);

    return(
        <Container>
            <Row className="mb-3">
                <Col>
                    <button
                    type="button"
                    className={`btn ${isEdit ? 'btn-warning' : 'btn-primary'}`}
                    onClick={() => setIsEdit(!isEdit)}
                    >
                        <i className="bx bx-edit-alt font-size-16 align-middle me-2"></i>
                        {isEdit ? 'Annulla modifica' : 'Modifica'}
                    </button>
                </Col>
            </Row>
            <Row>
                <Col>
                    {(updateEmailError && isEdit) ? <Alert color="danger">{updateEmailError}</Alert> : null}
                    {(updateEmailMessage && !isEdit) ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {updateEmailMessage}
                      </Alert>
                    ) : null} 
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">E-mail</Label>
                            <Input
                                type="email"
                                className="form-control"
                                placeholder="E-mail attuale"
                                disabled
                                value={user?.email}
                            />
                        </div>
                        {isEdit ? (
                            <>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">Nuova E-mail</Label>
                                    <Input
                                        name="email"
                                        type="email"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Inserisci nuova e-mail"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.email && validation.errors.email ? true : false
                                        }
                                    />
                                    {validation.touched.email && validation.errors.email ? (
                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">Ripeti nuova E-mail</Label>
                                    <Input
                                        name="emailConfirmation"
                                        type="email"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Ripeti nuova e-mail"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.emailConfirmation && validation.errors.emailConfirmation ? true : false
                                        }
                                    />
                                    {validation.touched.emailConfirmation && validation.errors.emailConfirmation ? (
                                        <FormFeedback type="invalid">{validation.errors.emailConfirmation}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3 d-flex justify-content-end">
                                    <Button
                                        color="primary"
                                        disabled={!(validation.isValid && validation.dirty)}
                                    >
                                        Modifica Email
                                    </Button>
                                </div>
                            </>
                        ): null}
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default FormEmail;