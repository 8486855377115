import { postUser } from "helpers/ara_helper";
import { call, put, takeLatest, delay } from "redux-saga/effects";
import { apiError,  } from "store/actions";
import { nuovoPeritoSuccess } from "./actions";
import { 
    ADMIN_PERITO_POST
} from "./actionTypes";

function* nuovoPerito({ payload }) {
  try {
    const response = yield call(postUser, payload);
    if(response){
      if(response?.status === "error"){
        yield put(apiError(response));
        return;
      }

      yield put(nuovoPeritoSuccess(response?.message))
    }
  } catch (error) {
    yield put(apiError(error?.response?.data));
  }
}

function* adminSaga() {
  yield takeLatest(ADMIN_PERITO_POST, nuovoPerito);
}
  
export default adminSaga;