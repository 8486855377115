import {
    PERIZIE_GET_SUCCESS
} from "./actionTypes"
  
const initialState = {
    perizieDataPaginate: null,
}
  
const Perizie = (state = initialState, action) => {
    switch (action.type) {
        case PERIZIE_GET_SUCCESS:
            state = {
                ...state,
                perizieDataPaginate: action.payload,
            }
        break
        default:
            state = { ...state }
        break
    }

    return state
}

export default Perizie
  