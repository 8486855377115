import { getListPerizie } from "helpers/ara_helper";
import { call, put, takeLatest, delay } from "redux-saga/effects";
import { apiError } from "store/actions";
import { getPerizieSuccess } from "./actions";
import { PERIZIE_GET } from "./actionTypes";

function* ottieniPerizie({ payload }) {
  try {
    const response = yield call(getListPerizie, payload);
    if(response){
      if(response?.status === "error"){
        yield put(apiError(response));
        return;
      }

      yield put(getPerizieSuccess(response?.data))
    }
  } catch (error) {
    yield put(apiError(error?.response?.data));
  }
}

function* perizieSaga() {
  yield takeLatest(PERIZIE_GET, ottieniPerizie);
}
  
export default perizieSaga;