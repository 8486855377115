export const UPLOAD_FILES = 'UPLOAD_FILES';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_FAILURE = 'UPLOAD_FAILURE';
export const GET_FILES = 'GET_FILES';
export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';
export const GET_PERIZIA_FILES = 'GET_PERIZIA_FILES';
export const GET_PERIZIA_FILES_SUCCESS = 'GET_PERIZIA_FILES_SUCCESS';
export const GET_PERIZIA_FILES_TO_CERTIFICATE = 'GET_PERIZIA_FILES_TO_CERTIFICATE';
export const GET_PERIZIA_FILES_TO_CERTIFICATE_SUCCESS = 'GET_PERIZIA_FILES_TO_CERTIFICATE_SUCCESS';
export const DELETE_FILES = 'DELETE_FILES';
export const DELETE_FILES_SUCCESS = 'DELETE_FILES_SUCCESS';
export const DELETE_FILES_ERROR = 'DELETE_FILES_ERROR';
export const POST_CERTIFICA_PERIZIA_FILES = 'POST_CERTIFICA_PERIZIA_FILES';
export const POST_CERTIFICA_PERIZIA_FILES_SUCCESS = 'POST_CERTIFICA_PERIZIA_FILES_SUCCESS';
export const POST_CERTIFICA_PERIZIA_FILES_ERROR = 'POST_CERTIFICA_PERIZIA_FILES_ERROR';