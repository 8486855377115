import { 
    UPLOAD_FILES,
    UPLOAD_PROGRESS,
    UPLOAD_SUCCESS,
    UPLOAD_FAILURE,
    GET_FILES,
    GET_FILES_SUCCESS,
    DELETE_FILES,
    DELETE_FILES_SUCCESS,
    GET_PERIZIA_FILES_SUCCESS,
    GET_PERIZIA_FILES,
    DELETE_FILES_ERROR,
    POST_CERTIFICA_PERIZIA_FILES,
    POST_CERTIFICA_PERIZIA_FILES_ERROR,
    POST_CERTIFICA_PERIZIA_FILES_SUCCESS,
    GET_PERIZIA_FILES_TO_CERTIFICATE,
    GET_PERIZIA_FILES_TO_CERTIFICATE_SUCCESS,
} from "./actionTypes";

// Azione per l'upload dei file
export const getFiles = (perizia_id) => ({
    type: GET_FILES,
    payload: { perizia_id },
});

export const getFilesToCertificate = (perizieIds) => ({
    type: GET_PERIZIA_FILES_TO_CERTIFICATE,
    payload: { perizieIds },
});

export const getFilesToCertificateSuccess = (perizieIds) => ({
    type: GET_PERIZIA_FILES_TO_CERTIFICATE_SUCCESS,
    payload: { perizieIds },
});

export const deleteFiles = (file_id) => ({
    type: DELETE_FILES,
    payload: { file_id },
});

export const deleteFilesSuccess = (status) => ({
    type: DELETE_FILES_SUCCESS,
    payload: { status },
});

export const deleteFilesError = (deleteErrorMessage) => ({
    type: DELETE_FILES_ERROR,
    payload: { deleteErrorMessage },
});

export const getFilesSuccess = (files) => ({
    type: GET_FILES_SUCCESS,
    payload: { files },
});

export const getPeriziaCertificateFiles = (perizia_id) => ({
    type: GET_PERIZIA_FILES,
    payload: { perizia_id },
});

export const getPeriziaCertificateFilesSuccess = (perizia) => ({
    type: GET_PERIZIA_FILES_SUCCESS,
    payload: { perizia },
});

export const getPeriziaFilesToCertificateSuccess = (perizieIds) => ({
    type: GET_PERIZIA_FILES_TO_CERTIFICATE_SUCCESS,
    payload: { perizieIds },
});

export const postCertificaPeriziaFiles = (perizia_id) => ({
    type: POST_CERTIFICA_PERIZIA_FILES,
    payload: { perizia_id },
});

export const postCertificaPeriziaFilesError = (message) => ({
    type: POST_CERTIFICA_PERIZIA_FILES_ERROR,
    payload: { message },
});

export const postCertificaPeriziaFilesSuccess = (message) => ({
    type: POST_CERTIFICA_PERIZIA_FILES_SUCCESS,
    payload: { message },
});

// Azione per l'upload dei file
export const uploadFiles = (files, perizia_id) => ({
    type: UPLOAD_FILES,
    payload: { files, perizia_id },
});
  
// Azione per l'aggiornamento del progresso di upload
export const updateProgress = (progress, fileName) => ({
    type: UPLOAD_PROGRESS,
    payload: { progress, fileName },
});

// Azione per l'upload completato con successo
export const uploadSuccess = () => ({
    type: UPLOAD_SUCCESS,
});

// Azione per l'upload fallito
export const uploadFailure = (error) => ({
    type: UPLOAD_FAILURE,
    payload: { error },
});