import React, { useState } from "react"
import {
    Col,
    Container, 
    Nav, 
    NavItem, 
    NavLink, 
    Row,
    TabContent,
    TabPane,
} from "reactstrap"
import classnames from "classnames";
import FormGenerali from "./FormGenerali";
import FormEmail from "./FormEmail";
import { useSelector } from "react-redux";
import FormPassword from "./FormPassword";

const Perizie = () => {
  //meta title
  document.title=`Profilo | ${process.env.REACT_APP_DOCUMENT_NAME}`;

    const { 
        user
    } = useSelector(state => ({
        user: state.Login?.user?.user,
    }));

    const [verticalActiveTab, setVerticalActiveTab] = useState("1")

  return (
    <div className="page-content">
        <Container fluid>
            <Row>
                <Col md="3">
                    <Nav pills className="flex-column">
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    "mb-2": true,
                                    active: verticalActiveTab === "1",
                                })}
                                onClick={() => {
                                    setVerticalActiveTab("1");
                                }}
                            >
                                Generali
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    "mb-2": true,
                                    active: verticalActiveTab === "2",
                                })}
                                onClick={() => {
                                    setVerticalActiveTab("2");
                                }}
                            >
                                E-mail
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    "mb-2": true,
                                    active: verticalActiveTab === "3",
                                })}
                                onClick={() => {
                                    setVerticalActiveTab("3");
                                }}
                            >
                                Password
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Col>
                <Col md="9">
                    <TabContent
                        activeTab={verticalActiveTab}
                        className="text-muted mt-4 mt-md-0">
                        <TabPane tabId="1">
                            <FormGenerali user={user} isActiveTab={verticalActiveTab === "1"} />
                        </TabPane>
                        <TabPane tabId="2">
                            <FormEmail user={user} isActiveTab={verticalActiveTab === "2"} />
                        </TabPane>
                        <TabPane tabId="3">
                            <FormPassword user={user} isActiveTab={verticalActiveTab === "3"} />
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </Container>
    </div>
  );
}

export default Perizie;
