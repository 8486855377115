import { 
    ADMIN_PERITO_POST, 
    ADMIN_PERITO_POST_SUCCESS 
} from "./actionTypes"
  
export const nuovoPerito = query => {
    return {
        type: ADMIN_PERITO_POST,
        payload: query,
    }
}

export const nuovoPeritoSuccess = data => {
    return {
        type: ADMIN_PERITO_POST_SUCCESS,
        payload: data,
    }
}