import axios from "axios";

//apply base url for axios
const API_URL = process.env.REACT_APP_APIURL;
const SYSTEM_TOKEN = process.env.REACT_APP_SYSTEMTOKEN;

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["systemtoken"] = SYSTEM_TOKEN;

axiosApi.interceptors.request.use((config) => {
  /** In dev, intercepts request and logs it into console for dev */
  // if (DEBUG) { console.info("✉️ ", config); }

  const token = localStorage.getItem("hailmanagement_token");
  if(token){
    config.headers.common["Authorization"] = `Bearer ${token}`;
  }

  return config;
}, (error) => {
  // if (DEBUG) { console.error("✉️ ", error); }
  return Promise.reject(error);
});

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post(url, data, config = {}) {
  let _data = { ...data };
  if(data instanceof FormData){
    _data = data;
  }

  return axiosApi
    .post(url, data, { ...config })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
