import React, { useEffect } from "react"
import { Modal, Form, Label, Input, FormFeedback } from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

const NewUserModal = ({
  isOpen,
  closeModal,
  createUserCallback
}) => {

  useEffect(() => {
    if(!isOpen){
      validation.resetForm()
    }
  },[isOpen])

  const validation = useFormik({
    enableReinitialize: false,
    initialValues: {
      codice_perito:'',
      email:'',
      surname:'',
      name:'',
      ruolo:'',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Inserisci Email"),
      name: Yup.string().required("Inserisci Name"),
      surname: Yup.string().required("Inserisci Surname"),
      codice_perito: Yup.string().required("Inserisci Codice perito"),
      ruolo: Yup.string().required("Inserisci Ruolo"),
    }),
    onSubmit: (values) => {
      createUserCallback(values);
    }
  });
    
  return(
    <Modal
      isOpen={isOpen}
      backdrop={'static'}
      id="newUserModal"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="newUserModalLabel">Aggiungi utente alla piattaforma</h5>
        <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <Form
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

          <div className="mb-3">
            <Label className="form-label">Codice Utente</Label>
            <Input
              name="codice_perito"
              className="form-control"
              placeholder="Codice perito"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.codice_perito || ""}
              invalid={
                validation.touched.codice_perito && validation.errors.codice_perito ? true : false
              }
            />
            {validation.touched.codice_perito && validation.errors.codice_perito ? (
              <FormFeedback type="invalid">{validation.errors.codice_perito}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label className="form-label">Cognome</Label>
            <Input
              name="surname"
              className="form-control"
              placeholder="Cognome"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.surname || ""}
              invalid={
                validation.touched.surname && validation.errors.surname ? true : false
              }
            />
            {validation.touched.surname && validation.errors.surname ? (
              <FormFeedback type="invalid">{validation.errors.surname}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label className="form-label">Nome</Label>
            <Input
              name="name"
              className="form-control"
              placeholder="Nome"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.name || ""}
              invalid={
                validation.touched.name && validation.errors.name ? true : false
              }
            />
            {validation.touched.name && validation.errors.name ? (
              <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label className="form-label">Email</Label>
            <Input
              name="email"
              className="form-control"
              placeholder="Codice perito"
              type="email"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email || ""}
              invalid={
                validation.touched.email && validation.errors.email ? true : false
              }
            />
            {validation.touched.email && validation.errors.email ? (
              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label className="form-label">Codice Utente</Label>
            <select onChange={validation.handleChange} value={validation.values.ruolo || ""} name="ruolo" className="form-control">
                <option>Seleziona</option>
                <option value={'Perito'}>Perito</option>
                <option value={'Ispettore'}>Ispettore</option>
            </select>
            {validation.touched.ruolo && validation.errors.ruolo ? (
              <FormFeedback type="invalid">{validation.errors.ruolo}</FormFeedback>
            ) : null}
          </div>
        </Form>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-light" onClick={closeModal}>Annulla</button>
        <button type="submit" onClick={() => validation.submitForm()} disabled={!(validation.isValid && validation.dirty)} className="btn btn-primary">Crea Utente</button>
      </div>
    </Modal>
  )
}

export default NewUserModal;