export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const VALIDATE_TOKEN = "VALIDATE_TOKEN"
export const ME_INFO = "ME_INFO"
export const ME_INFO_SUCCESS = "ME_INFO_SUCCESS"
export const FORGOT_PASSWORD = "FORGOT_PASSWORD"
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"

export const UPDATE_EMAIL = "UPDATE_EMAIL"
export const UPDATE_EMAIL_SUCCESS = "UPDATE_EMAIL_SUCCESS"

export const UPDATE_PASSWORD = "UPDATE_PASSWORD"
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS"

export const UPDATE_USER_DATA = "UPDATE_USER_DATA"
export const UPDATE_USER_DATA_SUCCESS = "UPDATE_USER_DATA_SUCCESS"
