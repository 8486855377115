import TableContainer from "components/Common/TableContainer";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Col, Modal, Row } from "reactstrap";
import { getFilesToCertificate, getPerizie, nuovoPerito, postCertificaPeriziaFiles, postCertificaPeriziaFilesError, postCertificaPeriziaFilesSuccess, updateProgress } from "store/actions";
// import { deletePerito, ottieniPeriti } from "store/users/actions";
// import NewUserModal from "./newUserModal";
import pdfImage from "../../assets/images/pdf-icon.png";
import mapsImage from "../../assets/images/maps-icon.png";
import moment from "moment";
import FileManager from "components/FileManager/FileManager";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SpinnerCircular } from "spinners-react";

const ListPerizie = () => {

    const [selectRemovedItem, setSelectedRemovedItem] = useState();
    const [openFileManager, setFileManager] = useState();
    const [openCertificaPerizie, setCertificaPerizie] = useState(false);
    const fetchObj = useRef();
    const [perizie, setPerizie] = useState([])
    const [perizieToCertificate, setPerizieToCertificate] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const { perizieDataPaginate, progress, perizieIds, certificaFilesErrorMessage, certificaFilesSuccessMessage } = useSelector(state => ({
        perizieDataPaginate: state.Perizie?.perizieDataPaginate,
        perizieIds: state.Files?.perizieIds,
        progress: state.Files?.progress,
        certificaFilesErrorMessage: state.Files?.certificaFilesErrorMessage,
        certificaFilesSuccessMessage: state.Files?.certificaFilesSuccessMessage,
    }));

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy, filters }) => {
        const query  = {"paginate":true, page:pageIndex+1};

        if(sortBy && sortBy?.length){
            const filterSortBy = sortBy.filter(sortBbj => (sortBbj?.id !== null && sortBbj?.id !== undefined));
            filterSortBy.forEach(sortObj => {
                query[sortObj.id] = !sortObj.desc ? 'asc' : 'desc';
            });
        }
        
        if(filters && filters?.length){
            query.text = filters[0].value;
        }

        fetchObj.current = query;

        dispatch(getPerizie(query));
    },[]);

    useEffect(() => {
        if(progress === 100){
            const timer = setTimeout(() => {
                dispatch(updateProgress(0, []));
              }, 1000);
            return () => clearTimeout(timer);
        }
    },[progress]);

    useEffect(() => {
        setPerizie(perizie?.map(perizia => {
            if(perizieIds?.length && perizieIds?.includes(perizia.id)){
                perizia.da_certificare = true;
            }
            return perizia
        }))
    },[perizieIds])

    useEffect(() => {
        if(perizieDataPaginate?.data?.length){
            dispatch(getFilesToCertificate(perizieDataPaginate.data?.map(perizia => perizia.id)));
        }
        setPerizie(perizieDataPaginate?.data)
    },[perizieDataPaginate]);

    useEffect(() => {
        if(certificaFilesErrorMessage){
            toast.error(certificaFilesErrorMessage);
            setIsLoading(false);
        }

        return () => {
            dispatch(postCertificaPeriziaFilesError(null));
        }
    },[certificaFilesErrorMessage]);

    useEffect(() => {
        if(certificaFilesSuccessMessage){
            toast.success(certificaFilesSuccessMessage);
            setIsLoading(false);
            setTimeout(() => {
                location.reload();
            }, 4000);
        }

        return () => {
            dispatch(postCertificaPeriziaFilesSuccess(null));
        }
    },[certificaFilesSuccessMessage]);
    
    const handleCheckboxChange = (value) => {
        if(perizieToCertificate?.includes(value)){
            const _perizieToCertificate = perizieToCertificate.filter((item) => item !== value);
            setPerizieToCertificate(_perizieToCertificate);
        }else{
            const _perizieToCertificate = [...perizieToCertificate];
            _perizieToCertificate.push(value)
            setPerizieToCertificate(_perizieToCertificate);
        }
    }

    const isChecked = (value) => {
        return perizieToCertificate?.includes(value);
    }
    
    const columns = useMemo(
        () => [
            {
                Header: 'SELECT',
                accessor: 'da_certificare',
                disableFilters: true,
                Cell: ({cell}) => {
                    return cell?.value ? 
                        <Button
                            color={isChecked(cell?.row?.original?.id) ? 'primary' : 'secondary'}
                            className={`btn btn-${isChecked(cell?.row?.original?.id) ? 'primary' : 'secondary'} `}
                            onClick={() => handleCheckboxChange(cell?.row?.original?.id)}
                        >
                            {isChecked(cell?.row?.original?.id) ? 'in attesa' : 'certifica'}
                        </Button>
                            
                        : null;
                },
            },
            {
                Header: 'DATA',
                accessor: 'data_perizia',
                disableFilters: true,
                Cell: ({cell}) => {
                    return <div>{moment(cell?.value).format('DD-MM-YYYY')}</div>;
                },
            },
            {
                Header: 'PERITO',
                accessor: 'codice_perito',
            },
            {
                Header: 'PIANTA',
                accessor: 'pianta',
                disableFilters: true,
            },
            {
                Header: 'LUOGO',
                accessor: 'luogo',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({cell}) => {
                    return (
                        <a href={`http://www.google.com/maps/place/${cell?.value}`} target="_blank" rel="noreferrer">
                            <img style={{height:40}} src={mapsImage} />
                        </a>
                    );
                },
            },
            {
                Header: 'PDF',
                accessor: 'pdf',
                disableSortBy: true,
                disableFilters: true,
                Cell: ({cell}) => {
                    return (
                        <a href={cell?.value} target="_blank" rel="noreferrer">
                            <img style={{height:40}} src={pdfImage} />
                        </a>
                    );
                },
            },
            {
                Header: 'AZIONI',
                accessor: '',
                disableSortBy: true,
                disableFilters: true,
                Cell: (({cell}) => {
                    return <div>
                        <button
                            onClick={() => setSelectedRemovedItem(cell?.row?.original?.id)}
                            type="button"
                            className="btn btn-danger"
                          >
                            <i className="mdi mdi-trash-can"></i>
                        </button>
                        <button
                            onClick={() => setFileManager(cell?.row?.original?.id)}
                            type="button"
                            className="btn btn-primary"
                            style={{marginLeft:5}}
                          >
                            <i className="mdi mdi-attachment"></i>
                        </button>
                    </div>
                })
            },
        ],
    [perizieToCertificate]);

    const certificate = () => {
        setIsLoading(true);
        dispatch(postCertificaPeriziaFiles(perizieToCertificate))
    }

    const deleteItem = () => {
        // dispatch(deletePerito(selectRemovedItem))
    }


    return(
        <>
            {progress ? 
                <div style={{
                    position:'absolute',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    width:'100%',
                    height:'100%',
                    top:0,
                    left:0,
                    zIndex:1051,
                    backgroundColor: "rgb(52 52 52 / 37%)"
                  }}>
                      <div style={{
                          position: 'absolute',
                          fontSize: 20,
                          fontWeight: 600,
                          color: 'black',
                      }}>{Math.trunc(progress)}%</div>
                      <SpinnerCircular size={100} />
                  </div>
            : null}
            <ToastContainer />
            {isLoading ? 
                <div style={{
                    position:'absolute',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    width:'100%',
                    height:'100%',
                    top:0,
                    left:0,
                    zIndex:1051,
                    backgroundColor: "rgb(52 52 52 / 37%)"
                  }}>
                      <SpinnerCircular size={100} />
                  </div>
            : null}
            
            <Alert color="warning" role="alert">
                Per ordinare colonne multiple tenere premuto il tasto della tastiera alt + click del mouse nelle colonne che si vogliono ordinare
            </Alert>
            <Modal
                size="lg"
                isOpen={openFileManager ? true : false}
                toggle={() => setFileManager()}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myLargeModalLabel"
                    >
                        File Manager
                    </h5>
                    <button
                        onClick={() => setFileManager()}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <FileManager perizia_id={openFileManager} />
                </div>
            </Modal>
            <Modal
                isOpen={openCertificaPerizie}
                toggle={() => setCertificaPerizie(false)}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myLargeModalLabel"
                    >
                        Certifica perizie
                    </h5>
                    <button
                        onClick={() => setCertificaPerizie(false)}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    Prima di certificare tutti i files per le perizie selezionate:
                      <ul>
                        {perizieToCertificate?.map(perizia_id => 
                            <li key={perizia_id}>{perizie?.filter(perizia => perizia.id === perizia_id)?.map(perizia => `${perizia.data_perizia}-${perizia.codice_perito}`)}</li>    
                        )}
                      </ul>
                    sei sicuro di averli controllati?
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => setCertificaPerizie(false)}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        Annulla
                    </button>
                    <button
                        type="button"
                        className="btn btn-success "
                        onClick={() => certificate()}
                    >
                        Certifica
                    </button>
                </div>
            </Modal>
            {perizieToCertificate?.length ? <Row>
                <Col style={{textAlign:'right'}}>
                    <Button
                      color="primary"
                      className="btn btn-primary "
                      onClick={() => setCertificaPerizie(true)}
                    >
                      Certifica perizie selezionate
                    </Button>
                </Col>
            </Row> : null}
            <TableContainer
                columns={columns}
                data={perizie || []}
                fetchData={fetchData}
                controlledPageCount={perizieDataPaginate?.last_page}
                customPageSizeOptions
                customPageSize={10}
                className="custom-header-css"
            />
             <Modal
                isOpen={selectRemovedItem ? true : false}
                backdrop={'static'}
                id="staticBackdrop"
            >
                <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Elimina Perizia</h5>
                <button type="button" className="btn-close"
                    onClick={() => {
                        setSelectedRemovedItem();
                    }} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <h2>Sei sicuro?</h2>
                    <p>Se si conferma, il sistema eliminerà la perizia.</p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-light" onClick={() => {
                        setSelectedRemovedItem();
                    }}>Annulla</button>
                    <button type="button" onClick={deleteItem} className="btn btn-danger">Procedi elimina Perizia</button>
                </div>
            </Modal>
            {/*<NewUserModal isOpen={newItemModalOpen} closeModal={() => setNewItemModalOpen(false)} createUserCallback={createUserCallback} /> */}
        </>
    )
}

export default ListPerizie