import {
    ADMIN_PERITO_POST, 
    ADMIN_PERITO_POST_SUCCESS
} from "./actionTypes"
  
const initialState = {
    newPeritoStatus: null
}
  
const Admin = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_PERITO_POST:
            state = {
                ...state,
                newPeritoStatus: null,
            }
        break
        case ADMIN_PERITO_POST_SUCCESS:
            state = {
                ...state,
                newPeritoStatus:action.payload
            }
        break
        default:
            state = { ...state }
        break
    }

    return state
}

export default Admin
  