import { 
  COLLEGA_PERITO_ISPETTORE_PUT,
  COLLEGA_PERITO_ISPETTORE_PUT_SUCCESS,
  SCOLLEGA_PERITO_ISPETTORE_PUT,
  SCOLLEGA_PERITO_ISPETTORE_PUT_SUCCESS,
  OTTIENI_PERITI, 
  OTTIENI_PERITI_NO_PAGINATE_SUCCESS, 
  OTTIENI_PERITI_SUCCESS, 
  PERITO_DELETE, 
  PERITO_DELETE_SUCCESS } from "./actionTypes"

export const ottieniPeriti = query => {
  return {
    type: OTTIENI_PERITI,
    payload: query,
  }
}

export const ottieniPeritiSuccess = data => {
  return {
    type: OTTIENI_PERITI_SUCCESS,
    payload: data,
  }
}

export const ottieniPeritiNoPaginateSuccess = data => {
  return {
    type: OTTIENI_PERITI_NO_PAGINATE_SUCCESS,
    payload: data,
  }
}

export const collegaPeritoIspettore = data => {
  return {
    type: COLLEGA_PERITO_ISPETTORE_PUT,
    payload: data,
  }
}

export const collegaPeritoIspettoreSuccess = data => {
  return {
    type: COLLEGA_PERITO_ISPETTORE_PUT_SUCCESS,
    payload: data,
  }
}

export const scollegaPeritoIspettore = data => {
  return {
    type: SCOLLEGA_PERITO_ISPETTORE_PUT,
    payload: data,
  }
}

export const scollegaPeritoIspettoreSuccess = data => {
  return {
    type: SCOLLEGA_PERITO_ISPETTORE_PUT_SUCCESS,
    payload: data,
  }
}

export const deletePerito = id => {
  return {
    type: PERITO_DELETE,
    payload: id,
  }
}

export const deletePeritoSuccess = status => {
  return {
    type: PERITO_DELETE_SUCCESS,
    payload: status,
  }
}