import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
    Row,
    Col,
    Card,
    Form,
    Nav,
    NavItem,
    NavLink,
    TabPane,
    TabContent,
    Progress,
    Modal,
    Toast,
    ToastHeader,
    ToastBody,
  } from "reactstrap"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone";
import { uploadFiles, updateProgress, getFiles, deleteFiles, getPeriziaCertificateFiles, deleteFilesError, postCertificaPeriziaFiles, postCertificaPeriziaFilesSuccess, postCertificaPeriziaFilesError, deleteFilesSuccess } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SpinnerCircular } from "spinners-react";

const acceptedFiles = [
    'application/msword', 
    'application/pdf', 
    'application/vnd.rar', 
    'application/zip', 
    'application/rtf', 
    'application/vnd.ms-excel', 
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 
    'application/vnd.ms-powerpoin', 
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
    'image/jpeg', 
    'image/png', 
    'image/gif', 
    'image/tiff', 
    'text/csv',
    'text/plain',
    'video/mp4', 
    'video/mpeg', 
    'video/mov', 
    'video/quicktime', 
    'video/x-msvideo',
];

const FileManager = ({ perizia_id }) => {
    const [activeTab, setActiveTab] = useState("1");
    const dispatch = useDispatch();
    const [selectedFiles, setselectedFiles] = useState([]);
    const [selectedDeleteFile, setselectedDeleteFile] = useState();
    const [filesTab, setFilesTab] = useState("1");
    const [isLoading, setIsLoading] = useState(false);
    const { 
        fileUploaded, 
        progress, 
        files, 
        perizia, 
        deleteErrorMessage, 
        certificaFilesSuccessMessage,  
        certificaFilesErrorMessage, 
        deleteFileSuccess} = useSelector(state => ({
        fileUploaded: state.Files?.fileUploaded,
        progress: state.Files?.progress,
        files: state.Files?.files,
        perizia: state.Files?.perizia,
        deleteErrorMessage: state.Files?.deleteErrorMessage,
        certificaFilesSuccessMessage: state.Files?.certificaFilesSuccessMessage,
        certificaFilesErrorMessage: state.Files?.certificaFilesErrorMessage,
        deleteFileSuccess: state.Files?.deleteFileSuccess,
    }));

    useEffect(() => {
        if(progress === 100){
            const timer = setTimeout(() => {
                setselectedFiles([]);
                uploadFiles([])
              }, 500);
            return () => clearTimeout(timer);
        }
    },[progress]);

    useEffect(() => {
        if(activeTab === "1"){
            dispatch(getPeriziaCertificateFiles(perizia_id));
        }
    }, [activeTab]);

    useEffect(() => {
        if(deleteErrorMessage){
            toast.error(deleteErrorMessage)
        }

        return () => {
            dispatch(deleteFilesError(null))
        }
    },[deleteErrorMessage])
    
    useEffect(() => {
        if(certificaFilesSuccessMessage){
            // toast.success(certificaFilesSuccessMessage);
            setIsLoading(false);
            dispatch(getPeriziaCertificateFiles(perizia_id));
        }

        return () => {
            dispatch(postCertificaPeriziaFilesSuccess(null));
        }
    },[certificaFilesSuccessMessage]);
    
    useEffect(() => {
        if(deleteFileSuccess){
            toast.success("File Eliminato correttamente");
            dispatch(getPeriziaCertificateFiles(perizia_id));
        }

        return () => {
            dispatch(deleteFilesSuccess(null));
        }
    },[deleteFileSuccess]);
    
    useEffect(() => {
        if(certificaFilesErrorMessage){
            // toast.error(certificaFilesErrorMessage);
            setIsLoading(false);
        }

        return () => {
            dispatch(postCertificaPeriziaFilesError(null));
        }
    },[certificaFilesErrorMessage]);

    function handleAcceptedFiles(files) {
        files.map(file => {
            return Object.assign(file, {
                preview: returnPreviewIcon(file, file?.type),
                formattedSize: formatBytes(file.size),
            });
        });

        setselectedFiles([...selectedFiles, ...files]);
    }

    function returnPreviewIcon(file, type, name) {
        let previewFile, previewIcon;
        switch (type) {
            case 'application/msword':
                previewIcon = "fas fa-file-word";
                break;
            case 'application/vnd.rar':
            case 'application/zip':
                previewIcon = "fas fa-file-archive";
                break;
            case 'application/pdf':
                previewIcon = "fas fa-file-pdf";
                break;
            case 'application/rtf':
                previewIcon = "fas fa-file-alt";
                break;
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                previewIcon = "fas fa-file-excel";
                break;
            case 'application/vnd.ms-powerpoin':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                previewIcon = "fas fa-file-powerpoint";
                break;
            case 'video/mp4':
            case 'video/mpeg':
            case 'video/x-msvideo':
            case 'video/quicktime':
                previewIcon = "fas fa-file-video";
                break;
            case 'image/jpeg':
            case 'image/png':
            case 'image/gif':
            case 'image/tiff':
                previewFile = <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt={typeof file === "string" ? name : file?.name}
                        src={typeof file === "string" ? file : URL.createObjectURL(file)}
                    /> 
                break;
            default:
                previewIcon = "fas fa-file";
                break;
        }

        if(!previewFile){
            previewFile = <i style={{fontSize:30, width:47, textAlign:'center'}} className={previewIcon}></i>
        }

        return previewFile;
    }

    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    const removeFileFromUpload = (e, i) => {
        e.preventDefault();
        const files = [...selectedFiles];
        files.splice(i, 1);
        setselectedFiles(files);
    }

    const handleUpload = (e) => {
        dispatch(updateProgress(0, []));
        dispatch(uploadFiles(selectedFiles, perizia_id));
    }

    const deleteFile = () => {
        dispatch(deleteFiles(selectedDeleteFile?.id));
        setselectedDeleteFile();
    }

    const statusColor = (status) => {
        switch (status) {
          case "pending":
            return "orange";
          case "completed":
            return "green";
          case "error":
            return "red";
            break;
          default:
        }
    
        return "rgba(127, 140, 141,1.0)";
    }
      
    const statusText = (status) => {
        switch (status) {
          case "pending":
            return "Pending";
          case "completed":
            return "Completed";
          case "error":
            return "Error";
            break;
          default:
        }
    
        return "da Certificare";
    }

    const cardCertificazione = (t, i) => {
        return <Card
            className="mt-1 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete"
            key={i + "-file"} style={{border:`1px solid ${statusColor(t.status)}`}}
            >
            <div className="p-2" style={{backgroundColor:statusColor(t.status), color:'white', fontWeight:500}}>
                Stato certificazione: {statusText(t.status)}
            </div>
            <div className="p-4">
                {t?.files.map((f, i) => 
                    <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                        >
                        <div className="p-2">
                            <Row className="align-items-center">
                                <Col className="col-auto">
                                    {returnPreviewIcon(f?.url, f?.mimeType, f?.name)}
                                </Col>
                                <Col>
                                    <a target="_blank" rel="noreferrer" href={f?.url} className="text-muted font-weight-bold">
                                        {f?.name}
                                    </a>
                                </Col>
                                {!t.status ? (
                                    <button onClick={(e) => setselectedDeleteFile(f)} className="close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                ) : null}
                            </Row>
                        </div>
                    </Card>
                )}
            </div>
            {t.status === "completed" ? 
                <div style={{padding:8}}>
                    <button
                        type="button"
                        className="btn btn-block btn-success "
                        style={{width:'100%'}}
                        onClick={(e) => {
                            e.preventDefault();
                            window.open(t?.storage_report_url, "_blank")
                        }}
                    >
                        Scarica certificazione
                    </button>
                </div>
            : null}
            
            {!t.status ? 
                <div style={{padding:8}}>
                    <button
                        type="button"
                        className="btn btn-block btn-primary "
                        style={{width:'100%'}}
                        onClick={(e) => {
                            e.preventDefault();
                            setIsLoading(true);
                            dispatch(postCertificaPeriziaFiles(perizia_id))
                        }}
                    >
                        Certifica Files
                    </button>
                </div>
            : null}
        </Card>
    }

    const trueScreenCompleted = [];
    const trueScreenToBeCompleted = [];
    perizia?.true_screen?.forEach(certificate => {
        if(!certificate?.status){
            trueScreenToBeCompleted.push(certificate);
        }else{
            trueScreenCompleted.push(certificate);
        }
    });

    return (
        <>
            <ToastContainer />
            {isLoading ? 
                <div style={{
                    position:'absolute',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    width:'100%',
                    height:'100%',
                    top:0,
                    left:0,
                    zIndex:1051,
                    backgroundColor: "rgb(52 52 52 / 37%)"
                  }}>
                      <SpinnerCircular size={100} />
                  </div>
            : null}
            <Modal
                isOpen={selectedDeleteFile ? true : false}
                toggle={() => setselectedDeleteFile()}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myLargeModalLabel"
                    >
                        Elimina File
                    </h5>
                    <button
                        onClick={() => setselectedDeleteFile()}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p>Sei sicuro di voler eliminare il file: {selectedDeleteFile?.filename}?</p>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => setselectedDeleteFile()}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        Annulla
                    </button>
                    <button
                        type="button"
                        className="btn btn-danger "
                        onClick={deleteFile}
                    >
                        Elimina
                    </button>
                </div>
            </Modal>
            <Nav pills className="navtab-bg nav-justified">
                <NavItem>
                    <NavLink
                    style={{ cursor: "pointer" }}
                    className={{
                        active: activeTab === "1",
                    }}
                    onClick={() => {
                        setActiveTab("1");
                    }}
                    >
                    Media
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                    style={{ cursor: "pointer" }}
                    className={{
                        active: activeTab === "2",
                    }}
                    onClick={() => {
                        setActiveTab("2");
                    }}
                    >
                    Upload
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={activeTab} className="p-3 text-muted">
                <TabPane tabId="1">
                    <Row>
                        <Col sm="12">

                        <Nav tabs className="nav-tabs-custom nav-justified">
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: filesTab === "1",
                                    })}
                                    onClick={() => {
                                        setFilesTab("1");
                                    }}
                                >
                                    <span className="d-block d-sm-none">
                                    <i className="fas fa-home"></i>
                                    </span>
                                    <span className="d-none d-sm-block">Da Certificare</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: filesTab === "2",
                                    })}
                                    onClick={() => {
                                        setFilesTab("2");
                                    }}
                                >
                                    <span className="d-block d-sm-none">
                                    <i className="far fa-user"></i>
                                    </span>
                                    <span className="d-none d-sm-block">Certificati</span>
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent
                            activeTab={filesTab}
                            className="p-3 text-muted"
                        >
                            <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                {trueScreenToBeCompleted?.length === 0 ? 
                                    <h2 style={{lineHeight:'240px'}} className="text-center">Nessun media presente</h2>
                                : null}
                                {trueScreenToBeCompleted?.map(cardCertificazione)}
                                </Col>
                            </Row>
                            </TabPane>
                            <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                {trueScreenCompleted?.length === 0 ? 
                                    <h2 style={{lineHeight:'240px'}} className="text-center">Nessun media presente</h2>
                                : null}
                                {trueScreenCompleted?.map(cardCertificazione)}
                                </Col>
                            </Row>
                            </TabPane>
                        </TabContent>


                            {/* {trueScreenCompleted?.length === 0 ? 
                                <h2 style={{lineHeight:'240px'}} className="text-center">Nessun media caricato</h2>
                            : null} */}

                        
                            
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <Row>
                        <Col sm="12">
                            <div>
                                <Form>
                                    <Dropzone
                                        accept={acceptedFiles}
                                        onDrop={acceptedFiles => {
                                            handleAcceptedFiles(acceptedFiles)
                                        }}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                            <div
                                            className="dz-message needsclick mt-2"
                                            {...getRootProps()}
                                            >
                                                <input {...getInputProps()} />
                                                <div className="mb-3">
                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                </div>
                                                <h4>Trascina i files qui o clicca per caricarli.</h4>
                                            </div>
                                        </div>
                                        )}
                                    </Dropzone>
                                    <div className="dropzone-previews mt-3" id="file-previews">
                                        {selectedFiles.map((f, i) => {
                                        return (
                                            <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            key={i + "-file"}
                                            >
                                                <div className="p-2">
                                                    <Row className="align-items-center">
                                                        <Col className="col-auto">
                                                            {f.preview}
                                                        </Col>
                                                        <Col>
                                                            <Link to="#" className="text-muted font-weight-bold">
                                                                {f.name}
                                                            </Link>
                                                            <p className="mb-0">
                                                                <strong>{f.formattedSize}</strong>
                                                            </p>
                                                            {fileUploaded?.includes(f.name) ? 
                                                                <p className="mb-0">
                                                                    <Progress
                                                                        value={100}
                                                                        color="success"
                                                                        className="progress-sm"
                                                                        style={{ width: '100%' }}
                                                                    ></Progress>
                                                                </p>
                                                            : null}
                                                            <button onClick={(e) => removeFileFromUpload(e, i)} className="close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Card>
                                        )
                                        })}
                                    </div>
                                </Form>
                                {selectedFiles?.length ? 
                                    <div style={{textAlign:'right'}} className="mt-4">
                                        <button
                                            onClick={handleUpload}
                                            type="button"
                                            className="btn btn-primary "
                                        >
                                            Invia i Files
                                        </button>
                                    </div>
                                : null}
                            </div>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </>




        
    )
}

export default FileManager