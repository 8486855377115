import { 
    PERIZIE_GET, 
    PERIZIE_GET_SUCCESS 
} from "./actionTypes"
  
export const getPerizie = query => {
    return {
        type: PERIZIE_GET,
        payload: query,
    }
}

export const getPerizieSuccess = data => {
    return {
        type: PERIZIE_GET_SUCCESS,
        payload: data,
    }
}