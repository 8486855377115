import React, { useCallback, useEffect, useState } from "react"
import { Modal, Form, Label, Input, FormFeedback } from "reactstrap";
import Select from 'react-select';
import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from "react-redux";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { ottieniPeriti } from "store/actions";

const AddPeritoModal = ({
  isOpen,
  closeModal,
  addPeritoCallback
}) => {

  const dispatch = useDispatch();
  const { users, usersSearchingLoading } = useSelector(state => ({
    users: state.Users?.users,
    usersSearchingLoading: state.Users?.usersSearchingLoading,
  }));

  const [formattedUsers, setFormattedUsers] = useState([])

  const validation = useFormik({
    enableReinitialize: false,
    initialValues: {
      perito:'',
    },
    validationSchema: Yup.object({
      perito: Yup.object().required("Seleziona Perito"),
    }),
    onSubmit: (values) => {
      addPeritoCallback(values);
    }
  });

  const onInputChange = (value) => {
    searchDebounce(value);
  }

  const searchDebounce = useCallback(debounce(value => {
    if(value && value?.length > 3){
      dispatch(ottieniPeriti({paginate:false, all:true, text:value}));
    }
  }, 300), [])

  useEffect(() => {
    if(!isOpen){
      validation.resetForm();
      setFormattedUsers([])
    }
  },[isOpen])

  useEffect(() => {
    if(users && Array.isArray(users)){
      const _users = users?.map(user => {return {label:`${user?.surname} ${user?.name}`, value:user?.id}});
      setFormattedUsers([{options:_users}]);
    }
  },[users]);

  return(
    <Modal
      isOpen={isOpen}
      backdrop={'static'}
      id="addPeritoModal"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="newUserModalLabel">Associa Perito</h5>
        <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <Form
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="mb-3 -select mt-3 mt-lg-0 select2-container">
            <Label>Ricerca Perito</Label>
            <Select
              value={validation.values.perito || ""}
              onChange={(value) => {
                validation.setFieldValue('perito', value)
              }}
              options={formattedUsers}
              classNamePrefix="select2-selection"
              isLoading={usersSearchingLoading}
              onInputChange={onInputChange}
            />
          </div>
        </Form>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-light" onClick={closeModal}>Annulla</button>
        <button type="submit" onClick={() => validation.submitForm()} disabled={!(validation.isValid && validation.dirty)} className="btn btn-primary">Invita Perito</button>
      </div>
    </Modal>
  )
}

export default AddPeritoModal;