import { userRoles } from "constants/layout";
import { userRole } from "helpers/utility";

import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "store/actions";

const FormGenerali = ({
    user,
    isActiveTab
}) => {

    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useDispatch();
    const validation = useFormik({    
        initialValues: {
          name: user?.perito?.name,
          surname: user?.perito?.surname
        },
        enableReinitialize:true,
        validationSchema: Yup.object({
          name: Yup.string().required("Please Enter Your Name"),
          surname: Yup.string().required("Please Enter Your Surname")
        }),
        onSubmit: (values) => {
            console.log(values);
            dispatch(updateUserData(values));
        }
    });

    const { updateDataMessage, updateDataError} = useSelector(state => ({
        updateDataError: state.Login.error,
        updateDataMessage: state.Login.updateDataMessage,
    }));

    useEffect(() => {
        if(updateDataMessage){
            setIsEdit(false);
        }
    },[updateDataMessage]);

    useEffect(() => {
        if(!isActiveTab){
            setIsEdit(false);
        }
    },[isActiveTab]);
    
    if(!user){
        return <></>
    }

    return(
        <>
            {userRole(user) === userRoles.ADMIN ? 
                <h2>Sei un Amministratore non hai nessun dato</h2> : 
            <Container>
                <Row className="mb-3">
                    <Col>
                        <button
                        type="button"
                        className={`btn ${isEdit ? 'btn-warning' : 'btn-primary'}`}
                        onClick={() => setIsEdit(!isEdit)}
                        >
                            <i className="bx bx-edit-alt font-size-16 align-middle me-2"></i>
                            {isEdit ? 'Annulla modifica' : 'Modifica'}
                        </button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {(updateDataError && isEdit) ? <Alert color="danger">{updateDataError}</Alert> : null}
                        {(updateDataMessage && !isEdit) ? (
                          <Alert color="success" style={{ marginTop: "13px" }}>
                            {updateDataMessage}
                          </Alert>
                        ) : null}
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}>
                            <>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">Codice Perito</Label>
                                    <Input
                                        className="form-control"
                                        placeholder="Codice perito"
                                        disabled
                                        value={user?.perito?.codice_perito}
                                    />
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">Nome</Label>
                                    <Input
                                        name="name"
                                        className="form-control"
                                        placeholder="Inserisci nome"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name}
                                        disabled={!isEdit}
                                        invalid={
                                            validation.touched.name && validation.errors.name ? true : false
                                        }
                                    />
                                    {validation.touched.name && validation.errors.name ? (
                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">Cognome</Label>
                                    <Input
                                        name="surname"
                                        className="form-control"
                                        placeholder="Inserisci cognome"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.surname}
                                        disabled={!isEdit}
                                        invalid={
                                            validation.touched.surname && validation.errors.surname ? true : false
                                        }
                                    />
                                    {validation.touched.surname && validation.errors.surname ? (
                                        <FormFeedback type="invalid">{validation.errors.surname}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3 d-flex justify-content-end">
                                    <Button
                                        color="primary"
                                        disabled={!(validation.isValid && validation.dirty && isEdit)}
                                    >
                                        Modifica Dati
                                    </Button>
                                </div>
                            </>
                        </Form>
                    </Col>
                </Row>
            </Container>}
            
        </>
    )
}

export default FormGenerali;