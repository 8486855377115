import {
    OTTIENI_PERITI_SUCCESS, 
    PERITO_DELETE, 
    PERITO_DELETE_SUCCESS,
    OTTIENI_PERITI_NO_PAGINATE_SUCCESS,
    OTTIENI_PERITI,
    COLLEGA_PERITO_ISPETTORE_PUT_SUCCESS,
    SCOLLEGA_PERITO_ISPETTORE_PUT_SUCCESS,
    SCOLLEGA_PERITO_ISPETTORE_PUT,
    COLLEGA_PERITO_ISPETTORE_PUT
} from "./actionTypes"
  
const initialState = {
    usersDataPaginate: null,
    deletePeritoStatus: null,
    collegaPeritoIspettoreMessage: null,
    scollegaPeritoIspettoreMessage: null,
    users:[],
    usersSearchingLoading:false,
}
  
const Users = (state = initialState, action) => {
    switch (action.type) {
        case OTTIENI_PERITI_SUCCESS:
            state = {
                ...state,
                usersDataPaginate: action.payload,
                usersSearchingLoading:false
            }
        break
        case COLLEGA_PERITO_ISPETTORE_PUT_SUCCESS:
            state = {
                ...state,
                collegaPeritoIspettoreMessage: action.payload
            }
        break
        case SCOLLEGA_PERITO_ISPETTORE_PUT_SUCCESS:
            state = {
                ...state,
                scollegaPeritoIspettoreMessage: action.payload
            }
        break
        case SCOLLEGA_PERITO_ISPETTORE_PUT:
            state = {
                ...state,
                scollegaPeritoIspettoreMessage:null
            }
        break
        case OTTIENI_PERITI:
            state = {
                ...state,
                users: action.payload,
                usersSearchingLoading:true
            }
        break
        case COLLEGA_PERITO_ISPETTORE_PUT:
            state = {
                ...state,
                collegaPeritoIspettoreMessage:null
            }
        break
        case OTTIENI_PERITI_NO_PAGINATE_SUCCESS:
            state = {
                ...state,
                users: action.payload,
                usersSearchingLoading:false
            }
        break
        case PERITO_DELETE:
            state = {
                ...state,
                deletePeritoStatus: null,
            }
        break
        case PERITO_DELETE_SUCCESS:
            state = {
                ...state,
                deletePeritoStatus: true,
            }
        break
        default:
            state = { ...state }
        break
    }

    return state
}

export default Users
  