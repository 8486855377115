import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import LayoutSaga from "./layout/saga"
import usersSaga from "./users/saga";
import adminSaga from "./admin/saga";
import perizieSaga from "./perizie/saga";
import filesSaga from "./files/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(usersSaga),
    fork(adminSaga),
    fork(perizieSaga),
    fork(filesSaga),
  ])
}
